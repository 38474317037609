<template>
    <div class="position-relative" :class="{'d-none': notificationSetting !== 'default'}">
        <div class="p-1 d-flex align-items-center justify-content-center bg-primary text-black">
            <strong>{{ $t('notifications.activatePushNotificationsRequest') }}</strong>
            <font-awesome-icon v-if="pending" icon="circle-notch" class="mx-3" spin />
            <div v-else class="btn btn-secondary mx-3" @click="setNotificationSetting">{{ $t('notifications.setPushNotificationSettings') }}</div>
        </div>
    </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useToast} from "vue-toastification";
import {$larafetch} from "~/utils/$larafetch";

const loading = ref(false)
const {$apiRoute} = useNuxtApp()
const toast = useToast()
const authStore = useAuthStore()

const config = useRuntimeConfig()
const notificationSetting = useState('notificationSetting', () => ref(''))
const pending = ref(false)

onMounted(() => {

    if (!('Notification' in window)) {
        notificationSetting.value = 'denied'
        return
    }

    notificationSetting.value = Notification.permission ?? 'default'

    //when a user manually disables and re-enables notifications, we need to register the new subscription
    if(notificationSetting.value === 'granted') {
        navigator.serviceWorker.ready.then(async (registration) => {
            const subscription = await registration.pushManager.getSubscription()
            if(subscription === null) {
                subscribeUserToPush()
            }
        })
    }
})

const setNotificationSetting = () => {
    pending.value = true
    if (!('Notification' in window)) {
        toast.info('Dieser Browser unterstützt keine Push-Notifications.')
        notificationSetting.value = 'denied'
        return
    }
    Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
            notificationSetting.value = 'granted'
            subscribeUserToPush()
            return
        }
        notificationSetting.value = 'denied'
    })
}

const subscribeUserToPush = () => {
    navigator.serviceWorker.ready.then(registration => {
        const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: config.public.vapidPublicKey,
        };

        return registration.pushManager.subscribe(subscribeOptions);
    })
        .then(pushSubscription => {
            sendSubscriptionToBackEnd(pushSubscription);
        });
}

const sendSubscriptionToBackEnd = (subscription) => {
    $larafetch($apiRoute('spa.users.update', {
            user: authStore.userData.id
        }),
        {
            method: 'PUT',
            body: {
                ...authStore.userData,
                mode: 'webPush',
                web_push: subscription
            }
        })
        .then(response => {
            toast.success('Du erhältst jetzt Push-Benachrichtigungen')
        })
        .catch(error => {
            toast.error('Push-Benachrichtigungen konnten nicht aktiviert werden')
        })
        .finally(() => {
            pending.value = false
        })
}

</script>

<style scoped>

</style>