<template>
    <div class="container-fluid db-shadow px-0 bg-white position-relative">
        <nav class="navbar navbar-expand-md container py-md-4 px-4 px-md-5 mb-4" aria-label="Navigation">

            <!--dealbuzzer logo-->
            <div class="text-center">
                <nuxt-link to="/" class="navbar-brand me-0">
                    <Logo/>
                </nuxt-link>
                <div class="small">
                    <nuxt-link class="fw-bold text-decoration-none badge text-bg-primary"
                               to="/organisation/subscriptions">{{ useAuthStore().subscription?.plan?.name }}
                        <span v-if="useAuthStore().subscription?.trial">
                            ({{
                                $t('header.trialUntilShort')
                            }} {{ dayjs(useAuthStore().subscription?.trialUntil).format('L') }})
                        </span>
                    </nuxt-link>
                </div>
            </div>

            <div class="d-flex flex-grow-1 align-items-center justify-content-end">
                <!--offcanvas menu-->
                <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar"
                     aria-labelledby="offcanvasNavbarLabel"
                     aria-modal="false">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                            {{ $t('nav.navigation') }}
                        </h5>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                id="offcanvasClose"></button>
                    </div>
                    <div class="offcanvas-body">
                        <NavigationLinks/>
                    </div>
                </div>

                <!-- notifications -->
                <div class="d-flex justify-content-center align-items-center gap-4">
                    <div class="dropdown position-static pe-2">
                        <a ref="notificationsDropdownToggle" href="#" role="button" id="dropdownNotifications"
                           data-bs-toggle="dropdown"
                           aria-expanded="false">
                            <Tooltip :content="$t('notifications.title')" placement="bottom">
                                <div class="position-relative">
                                    <font-awesome-icon icon="bell" size="xl"/>
                                    <small v-if="unreadNotificationsCount > 0"
                                           class="notification-badge position-absolute top-0 start-100 badge badge-danger bg-danger border border-light rounded-circle d-flex justify-content-center align-items-center">
                                        {{ unreadNotificationsCount }}
                                        <span class="visually-hidden">New alerts</span>
                                    </small>
                                </div>
                            </Tooltip>
                        </a>
                        <div ref="notificationsDropdown" class="dropdown-menu db-shadow notification-dropdown"
                             aria-labelledby="dropdownNotifications"
                             @click.stop.prevent="">
                            <p v-if="!unreadNotifications?.length && !readNotifications?.length"
                               class="mx-3 my-3 text-center">{{ $t('notifications.none') }}</p>
                            <div v-if="unreadNotifications?.length" class="pb-1">
                                <p class="mx-3 mt-3 mb-4 h4 fw-bold">
                                    {{ $t('common.new') }}
                                </p>
                                <ul class="list-unstyled">
                                    <li v-for="notification in unreadNotifications">
                                        <div class="mb-3">
                                            <p>
                                                <span v-html="notification.data.content"></span>
                                                &nbsp;
                                                <small class="text-muted">
                                                    {{ dayjs(new Date(notification.created_at)).fromNow() }}
                                                </small>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="readNotifications?.length">
                                <p class="mx-3 mt-3 mb-4 h4 fw-bold">
                                    {{ $t('notifications.lastDays', {days: 30}) }}
                                </p>
                                <ul class="list-unstyled">
                                    <li v-for="notification in limitedReadNotifications">
                                        <div class="mb-3">
                                            <p>
                                                <span v-html="notification.data.content"></span>
                                                &nbsp;
                                                <small class="text-muted">
                                                    {{ dayjs(new Date(notification.created_at)).fromNow() }}
                                                </small>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <div v-if="readNotifications.length > limitCount"
                                     class="pb-3 d-flex justify-content-center">
                                    <p class="mb-0 cursor-pointer hover-text-decoration"
                                       @click="limited = !limited">
                                        <small>{{ (limited) ? $t('common.showMore') : $t('common.showLess') }}</small>
                                    </p>
                                </div>
                                <div v-if="notificationSetting === 'denied'" class="m-3 p-3 bg-secondary text-light">
                                    <font-awesome-icon icon="info-circle" class="pe-1"/>
                                    {{ $t('notifications.pushInactiveInfo') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--mute button-->
                    <Tooltip :content="muted ? $t('nav.unmuteSound') : $t('nav.muteSound')" placement="bottom">
                    <div @click="toggleMute()" class="cursor-pointer">
                        <font-awesome-icon :icon="muted ? 'volume-mute' : 'volume-up'" size="xl"/>
                    </div>
                    </Tooltip>

                    <!--user icon-->
                    <div class="dropdown">
                        <button class="d-flex align-items-center justify-content between dropdown-toggle-nav btn p-0"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                @click.stop="active = !active">
                            <profile-image :image="useAuthStore().userData?.image"
                                           :title="useAuthStore().userData?.firstname"
                                           :size="'sm'" class="d-flex"/>
                            <font-awesome-icon
                                :icon="(active)?'fa-solid fa-chevron-up':' fa-solid fa-chevron-down'"
                                class="ps-2"/>
                        </button>

                        <ul class="dropdown-menu dropdown-menu-end dropdown-user-icon db-shadow">
                            <li class="px-3 py-2 small">
                                <div class="whitespace-no-wrap">
                                    <span class="fw-bold">{{
                                            $t('header.plan')
                                        }}: {{ useAuthStore().subscription?.plan?.name }}</span>
                                    &nbsp;<nuxt-link to="/organisation/subscriptions">{{
                                        $t('header.manage')
                                    }}
                                </nuxt-link>
                                </div>
                                <span v-if="useAuthStore().subscription?.trial">
                                {{
                                        $t('header.trialUntilLong')
                                    }} {{ dayjs(useAuthStore().subscription?.trialUntil).format('L') }}
                                </span>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li>
                                <a class="dropdown-item cursor-pointer"
                                   @click="useEventBus('showNewEditUserModal').emit()">{{
                                        $t('nav.userNav.userSettings')
                                    }}</a>
                            </li>
                            <li v-if="$can('viewAny', 'users')">
                                <nuxt-link :to="localePath({ name: 'organisation-settings'})" class="dropdown-item">
                                    {{ $t('nav.userNav.organisationSettings') }}
                                </nuxt-link>
                            </li>
                            <li>
                                <nuxt-link :to="localePath({ name: 'system-releaselog'})" class="dropdown-item">
                                    {{ $t('nav.userNav.releaselog') }}
                                </nuxt-link>
                            </li>
                            <li><a class="dropdown-item cursor-pointer" @click="logout">{{ $t('auth.logout') }}</a></li>
                        </ul>
                    </div>
                </div>

                <!--offcanvas menu toggler-->
                <!--            <button class="navbar-toggler border-0 ms-3 p-0" type="button" data-bs-toggle="offcanvas"-->
                <!--                    data-bs-target="#offcanvasNavbar"-->
                <!--                    aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">-->
                <!--                <font-awesome-icon-->
                <!--                    icon="fa-solid fa-bars" class="navbar-toggler-icon"/>-->
                <!--            </button>-->
            </div>
        </nav>

        <Sidebar v-if="sidebarStore.isOverlay" :overlay="true"/>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import Sidebar from "@/components/layouts/Sidebar";
import {useAuthStore} from "@/store/auth"
import NavigationLinks from "@/components/layouts/NavigationLinks"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {useLocalStorage} from "@vueuse/core"
import {useEventBus} from '@vueuse/core'
import Logo from "@/components/Logo"
import {useSidebarStore} from "@/store/sidebar";

const muted = useLocalStorage('muted', false)
const notificationSetting = useState('notificationSetting')
const sidebarStore = useSidebarStore()

function toggleMute() {
    muted.value = !muted.value
}

const active = ref(false)

function close() {
    active.value = false
}

const dayjs = useDayjs()
const notificationsDropdown = ref(null)
const notificationsDropdownToggle = ref(null)

onMounted(() => {
    document.addEventListener('click', close)
    notificationsDropdownToggle.value.addEventListener('shown.bs.dropdown', function () {
        markAsRead()
    })
    notificationsDropdownToggle.value.addEventListener('hidden.bs.dropdown', function () {
        notificationsRefresh()
    })
})

onBeforeUnmount(() => {
    document.removeEventListener('click', close)
    notificationsDropdownToggle.value?.removeEventListener('shown.bs.dropdown', function () {
        markAsRead()
    })
    notificationsDropdownToggle.value?.removeEventListener('hidden.bs.dropdown', function () {
        notificationsRefresh()
    })
})

function logout() {
    useAuthStore().logout()
}

//notifications
const limited = ref(true)
const limitCount = 5

const {data: notifications, refresh: notificationsRefresh} = await useAsyncData('notifications', () =>
    $larafetch(useNuxtApp().$apiRoute('spa.notifications.index',), {
        method: 'GET',
    }).catch(errors => {
        console.log(errors)
        return {data: []}
    })
)

const unreadNotificationsCount = computed(() => {
    return (unreadNotifications.value) ? unreadNotifications?.value?.length : 0
})

const unreadNotifications = computed(() => {
    return (notifications.value) ? notifications.value.filter(notification => !notification.read_at) : []
})

const readNotifications = computed(() => {
    return (notifications.value) ? notifications.value.filter(notification => notification.read_at) : []
})

const limitedReadNotifications = computed(() => {
    if (limited.value) return readNotifications.value.slice(0, limitCount);
    return readNotifications.value
})

watch(() => unreadNotificationsCount.value, (count) => {
    //check for browser support
    if (navigator.setAppBadge) {
        //set app badge
        count > 0 ? navigator.setAppBadge(count) : navigator.clearAppBadge()
    }
})

function markAsRead() {
    $larafetch(useNuxtApp().$apiRoute('spa.notifications.markAsRead',), {
        method: 'PUT',
    }).catch(errors => {
        console.log(errors)
    })
}

useEventBus('eventClaimed').on((event) => {
    notificationsRefresh()
})
</script>

<style scoped>
.notification-badge {
    transform: translate(-50%, -40%);
    width: 1.5rem;
    height: 1.5rem;
}
</style>