<template>
    <v-form v-slot="{ errors }" @submit="onSubmit" ref="formValidator">
        <Modal :show="show" @update:show="$emit('update:show', $event)" @modal:shown="init" ref="modal">

            <template #header>
                <div class="px-3 ps-xs-5 d-flex flex-grow-1 bg-user-modal primary flex-column-reverse flex-xs-row" ref="modalHead">
                    <UserImage v-model="model" :partOfModal="true"/>
                    <div class="d-flex flex-column flex-grow-1 py-3">
                        <div class="align-self-end">
                            <button type="button"
                                    class="btn bg-black btn-close-modal d-flex align-items-center justify-content-center"
                                    @click.stop="$refs.modal.closeModal()">
                                <font-awesome-icon icon="fa-solid fa-xmark" class="cursor-pointer p-2 text-white"/>
                            </button>
                        </div>
                        <div class="px-3 pe-xs-5" ref="modalTitle">
                            <h3 class="text-black fw-bold px-2 text-center text-xs-start">{{ model.firstname }} {{ model.lastname }}</h3>
                            <p class="text-black fw-bold px-2 text-center text-xs-start">{{ model.organisation_name }}</p>
                        </div>
                    </div>
                </div>
            </template>

            <UserData v-model="model" class="mx-5"/>

            <UserDefaultDevice v-model="model" class="mx-5 mt-5"/>

            <UserSound v-model="model" class="mx-5"/>

            <template #footer>
                <div class="pb-5 px-5 flex-grow-1">
                    <button :disabled="submitLoading" type="primary" class="btn btn-primary btn-block mt-5"
                            native-type="submit">
                        <span v-if="!submitLoading">{{$t("common.save")}}</span>
                        <span v-else><font-awesome-icon spin icon="circle-notch"/> {{$t("common.pleaseWait")}}</span>
                    </button>
                </div>
            </template>
        </Modal>
    </v-form>
</template>

<script setup>
import UserImage from "@/components/pages/users/UserImage"
import UserData from "@/components/pages/users/UserData";
import UserDefaultDevice from "@/components/pages/users/UserDefaultDevice";
import UserSound from "@/components/pages/users/UserSound";
import Modal from "@/components/farbcode-core/Modal";
import {useToast} from "vue-toastification";
import {useAuthStore} from "@/store/auth";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const authStore = useAuthStore()
const { $apiRoute } = useNuxtApp()

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    }
})

const emit = defineEmits(['update:show'])

const loading = ref(false)
const submitLoading = ref(false)
const model = ref({})

//Header Background Color Height-->
const modalTitle = ref(null)
const modalHead = ref(null)

function init() {
    model.value = {...authStore.userData}

    setTimeout(() => {
        modalHead.value.style.setProperty('--modalTitleHeight', `${modalTitle.value.scrollHeight + 45}px`)
    }, 50)
}

async function refresh() {
    await authStore.getAuthData()
    model.value = {...authStore.userData}
}

async function onSubmit(values, actions) {
    submitLoading.value = true

    $larafetch($apiRoute('spa.users.update', {user: authStore.userData.id}), {
        method: "PUT",
        body: {...model.value}
    }).then((response) => {
        emit('update:show', false)
        useToast().success(t('user.saveSuccess'))
        // refresh user
        refresh()
    }).catch((error) => {
        useToast().error(error.data?.message)
        actions.setErrors(error.data?.errors);
    }).finally(() => {
        submitLoading.value = false
    })
}
</script>
