<template>
    <div v-if="useAuthStore().impersonate || $can('impersonate', 'users')" class="position-relative impersonate-wrapper" :class="{'show':(useAuthStore().impersonate)}">
        <div class="impersonate-badge d-flex align-items-center justify-content-center bg-primary text-black">
            <strong>{{ $t('unterwegsAls', {organisation_name: useAuthStore().userData?.organisation_name, firstname: useAuthStore().userData?.firstname, lastname: useAuthStore().userData?.lastname}) }}</strong>
            <div class="ms-4">
                <a v-if="!loading" @click="leaveImpersonation" class="cursor-pointer text-decoration-none"><small>{{ $t('common.back') }}</small></a>
                <span v-else><font-awesome-icon icon="circle-notch"/></span>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useToast} from "vue-toastification";
import {$larafetch} from "~/utils/$larafetch";
const loading = ref(false)
const {$apiRoute} = useNuxtApp()
const authStore = useAuthStore()
const toast = useToast()
async function leaveImpersonation() {
    loading.value = true
    await $larafetch($apiRoute('spa.leaveImpersonation'), {
        params: {user: authStore.userData.id}
    }).then(response => {
        if (response === 'disabled') {
            useAuthStore().getAuthData()
            useToast().success('Erfolgreich. Wieder im eigenen Namen unterwegs')
            useRouter().go()
        }
    }).catch(error => {
        toasts.error(error.data?.message)
    }).finally(() => {
        loading.value = false
    })
}
</script>

<style scoped>

</style>